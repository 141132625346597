import type { FunctionComponent } from "preact";
import { useCallback } from "preact/hooks";

import { useStore } from "@nanostores/preact";
import clsx from "clsx";
import { FirebaseError } from "firebase/app";
import { GoogleAuthProvider } from "firebase/auth";
import LogRocket from "logrocket";

import { signInWithProvider } from "@/services/auth";
import { authStore } from "@/stores/authstore";

import { showError } from "../../services/toasts";

export const SignInWithGoogle: FunctionComponent = () => {
  const { loading } = useStore(authStore);

  const signInWithGoogle = useCallback(async () => {
    authStore.setKey("loading", true);
    const provider = new GoogleAuthProvider();

    try {
      await signInWithProvider(provider);
    } catch (err) {
      if (err instanceof FirebaseError) {
        showError(err.message);
        authStore.setKey("loading", false);
      } else {
        LogRocket.captureException(err as Error);
      }
    }
  }, []);

  return (
    <button
      disabled={loading}
      id="sign-in-with-google"
      onClick={signInWithGoogle}
      class={clsx("btn max-w-96 w-full", { loading })}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
        <path d="M3.06364 7.50914C4.70909 4.24092 8.09084 2 12 2C14.6954 2 16.959 2.99095 18.6909 4.60455L15.8227 7.47274C14.7864 6.48185 13.4681 5.97727 12 5.97727C9.39542 5.97727 7.19084 7.73637 6.40455 10.1C6.2045 10.7 6.09086 11.3409 6.09086 12C6.09086 12.6591 6.2045 13.3 6.40455 13.9C7.19084 16.2636 9.39542 18.0227 12 18.0227C13.3454 18.0227 14.4909 17.6682 15.3864 17.0682C16.4454 16.3591 17.15 15.3 17.3818 14.05H12V10.1818H21.4181C21.5364 10.8363 21.6 11.5182 21.6 12.2273C21.6 15.2727 20.5091 17.8363 18.6181 19.5773C16.9636 21.1046 14.7 22 12 22C8.09084 22 4.70909 19.7591 3.06364 16.4909C2.38638 15.1409 2 13.6136 2 12C2 10.3864 2.38638 8.85911 3.06364 7.50914Z"></path>
      </svg>
      <span>Sign in with Google</span>
    </button>
  );
};

export default SignInWithGoogle;
